<template>
  <div class="levedetail">
    <div class="_SelectHeader" style="padding: 15px 20px 5px 20px">
      <p class="round"></p>
      <p style="font-size: 8px; font-weight: bold">会员管理-会员详情</p>
    </div>
    <div class="_SelectHeader selectHeader2">
      <i
        @click="$router.go(-1)"
        style="
          font-size: 20px;
          font-weight: bold;
          color: #f5b837;
          margin-right: 20px;
        "
        class="el-icon-arrow-left"
      ></i>
      <div>
        <span class="mark">佛山</span>
        <span style="font-size: 15px">{{
          JSON.parse(this.$route.query.integralItem).compName
        }}</span>
      </div>
      <div>
        <img src="../../assets/images/level2.png" alt="" />
        <span>LV.1</span>
      </div>
      <div>
        <img src="../../assets/images/level1.png" alt="" />
        <span>LV.1</span>
      </div>
      <div>
        <img src="../../assets/images/level3.png" alt="" />
        <span>LV.1</span>
      </div>
    </div>
    <p style="padding: 10px 40px 15px 40px">跟进记录</p>
    <div class="timeline">
      <span class="title" @click="handleAddOfEdit(true, null)">新增跟进</span>
      <el-timeline>
        <el-timeline-item
          placement="top"
          icon="el-icon-time"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div class="timeline-header">
            <div>
              <span style="margin-right: 15px">{{
                new Date(item.saveTime).toLocaleString()
              }}</span>
              <span>{{ item.dutyUserName }}</span>
            </div>
            <div>
              <i
                style="margin-right: 15px"
                class="el-icon-delete"
                @click="deleteItem(item)"
              ></i>
              <i class="el-icon-edit" @click="handleAddOfEdit(false, item)"></i>
            </div>
          </div>
          <el-card>
            <p>{{ item.abstract }}</p>
            <p style="color: #999999; font-size: 13px; margin-top: 10px">
              {{ item.remark }}
            </p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
    <el-dialog
      title="新增跟进记录"
      class="recordDialog"
      :visible.sync="showDialog"
      width="25%"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="公司名称">
          <el-input v-model="compName" disabled></el-input>
        </el-form-item>
        <el-form-item label="摘要">
          <el-input v-model="abstract"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="remark"></el-input>
        </el-form-item>
        <el-form-item label="金额">
          <el-input v-model="amount"></el-input>
        </el-form-item>
        <el-form-item label="消费类型">
          <el-select v-model="consumeType" clearable placeholder="请选择">
            <el-option
              v-for="item in [
                { name: '消费', value: 0 },
                {
                  name: '赠送',
                  value: 1,
                },
              ]"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取消</el-button>
        <el-button type="primary" @click="reqData">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, put, deleteMethod } from "../../api/http";
export default {
  data() {
    return {
      time: "12u00A0122",
      dataList: [],
      compName: JSON.parse(this.$route.query.integralItem).compName,
      abstract: "",
      amount: "",
      remark: "",
      consumeType: null,
      showDialog: false,
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      get(
        "/api/MemberConsume?CompId=" +
          JSON.parse(this.$route.query.integralItem).compId
      ).then((resp) => {
        if (resp.code == 200) {
          this.dataList = resp.data;
        }
      });
    },
    handleAddOfEdit(isAdd, item) {
      this.isAdd = isAdd;
      if (!isAdd) this.dataItem = item;
      this.showDialog = true;
      this.abstract = isAdd ? "" : item.abstract;
      this.amount = isAdd ? "" : item.amount;
      this.consumeType = isAdd ? "" : item.consumeType;
      this.remark = isAdd ? "" : item.remark;
    },
    reqData() {
      var methods = this.isAdd ? post : put;
      methods("/api/MemberConsume", {
        id: this.isAdd ? 0 : this.dataItem.id,
        compId: JSON.parse(this.$route.query.integralItem).compId,
        abstract: this.abstract,
        amount: this.amount,
        consumeType: this.consumeType,
        remark: this.remark,
        dutyUserId: this.$store.state.userInfo.id,
        dutyUserName: this.$store.state.userInfo.userName,
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: (this.isAdd ? "新增" : "修改") + "成功",
            });
            this.showDialog = false;
            this.getDataList();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error((this.isAdd ? "新增" : "修改") + "失败");
        });
    },
    deleteItem(row) {
      this.$confirm("您确认要删除“" + row.abstract + "”这条跟进记录信息?")
        .then(() => {
          deleteMethod("/api/MemberConsume?Id=" + row.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.getDataList();
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
.levedetail {
  background-color: white;
  .round {
    width: 6.5px;
    height: 6px;
    border-radius: 50px;
    border: 2px solid #4f89ff;
    margin-right: 10px;
  }

  .selectHeader2 {
    background-color: white;
    padding: 15px 20px;
    background: #fafbfc;
    .mark {
      background-color: #f5b837;
      border-radius: 50px;
      color: white;
      padding: 10px 5px;
      font-size: 15px;
      margin-right: 8px;
    }
    > div {
      display: flex;
      align-items: center;
      margin-right: 60px;
      color: #2a4b8e;
      img {
        width: 15px;
        height: 15px;
        margin: 0 5px;
      }
    }
  }
  .timeline {
    padding-left: 10px;
    margin-right: 30px;
    margin-left: 20px;
    padding-right: 30px;
    background: #fafbfc;
    border-top: 1px solid #c0c0c08e;
    .title {
      padding: 8px 20px;
      background-color: #4664fa;
      color: white;
      border-radius: 50px;
      display: inline-block;
      margin: 15px 0;
    }
    .el-timeline {
      background-color: white;
      padding: 20px;
      height: 330px;
      overflow-y: scroll;
    }
    /deep/.el-timeline-item__content {
      width: 80%;
    }
    /deep/.el-card {
      background-color: #ecf5fe !important;
    }
    /deep/.el-card.is-always-shadow,
    .el-card.is-hover-shadow:focus,
    .el-card.is-hover-shadow:hover {
      box-shadow: none !important;
    }
    /deep/ .el-timeline-item__tail {
      border-width: 1px;
      border-color: #b5dccf;
      border-radius: 10px;
      border-style: dashed;
    }
    /deep/ .el-timeline-item__icon {
      color: #0bc98a !important;
    }
    /deep/ .el-timeline-item__node {
      padding: 5px 0 !important;
      background-color: white;
    }
    /deep/.el-timeline .el-timeline-item:last-child .el-timeline-item__tail {
      display: block !important;
    }
    /deep/ .el-timeline-item__icon {
      font-size: 17px !important;
    }
    .timeline-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: -6px;
      padding-bottom: 10px;
      font-size: 13px;
      > div {
        display: flex;
        align-items: center;
      }
    }

    .el-icon-time {
      font-size: 28px !important;
    }
  }
  .el-form-item {
    margin-bottom: 10px !important;
  }
  .el-input {
    width: 200px !important;
  }
}
</style>