import { render, staticRenderFns } from "./LevelDetail.vue?vue&type=template&id=06587d19&scoped=true"
import script from "./LevelDetail.vue?vue&type=script&lang=js"
export * from "./LevelDetail.vue?vue&type=script&lang=js"
import style0 from "./LevelDetail.vue?vue&type=style&index=0&id=06587d19&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06587d19",
  null
  
)

export default component.exports